.tracking-container {
    overflow-x: auto;
}

.tracking-container table {
    border-collapse: collapse;
    width: 100%;
}

table.tracking-container table tfoot {
    background: #F2F2F2;
}

.tracking-container table th {
    height: 32px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #009fda;
    background: #b1dbea;
    border: 1px solid #009fda;
    border-width: 1px 0;
    text-align: left;
    padding: 3px 8px;
}

.tracking-container table td {
    padding-left: 10px;
    font-size: 14px;
    line-height: 19px;
    height: 30px;
    vertical-align: middle;
    padding-bottom: 5px;
}

.tracking-container table tr:hover {
    background: #F2F2F2;
}

.tracking-container table img {
    margin-right: 10px;
    max-width: 97%;
}

.tracking-container table thead {
    background: #fc9;
}

.tracking-container h1 {
    font-size: 16px;
    font-weight: 600;
    margin: 16px 0;
}
