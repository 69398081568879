#payment-processing-rec1 {
    animation: payment-processing-rec1__sz 3000ms linear infinite normal forwards
}

@keyframes payment-processing-rec1__sz {
    0% {
        width: 24px;
        height: 4px
    }
    10% {
        width: 40px;
        height: 4px
    }
    20% {
        width: 64px;
        height: 4px
    }
    30% {
        width: 32px;
        height: 4px
    }
    40% {
        width: 8px;
        height: 4px
    }
    50% {
        width: 48px;
        height: 4px
    }
    60% {
        width: 64px;
        height: 4px
    }
    70% {
        width: 40px;
        height: 4px
    }
    80% {
        width: 24px;
        height: 4px
    }
    90% {
        width: 8px;
        height: 4px
    }
    100% {
        width: 32px;
        height: 4px
    }
}

#payment-processing-rec2 {
    animation: payment-processing-rec2__sz 3000ms linear infinite normal forwards
}

@keyframes payment-processing-rec2__sz {
    0% {
        width: 64px;
        height: 4px
    }
    10% {
        width: 48px;
        height: 4px
    }
    20% {
        width: 24px;
        height: 4px
    }
    30% {
        width: 8px;
        height: 4px
    }
    40% {
        width: 32px;
        height: 4px
    }
    50% {
        width: 64px;
        height: 4px
    }
    60% {
        width: 24px;
        height: 4px
    }
    70% {
        width: 16px;
        height: 4px
    }
    80% {
        width: 48px;
        height: 4px
    }
    90% {
        width: 16px;
        height: 4px
    }
    100% {
        width: 64px;
        height: 4px
    }
}

#payment-processing-rec3 {
    animation: payment-processing-rec3__sz 3000ms linear infinite normal forwards
}

@keyframes payment-processing-rec3__sz {
    0% {
        width: 40px;
        height: 4px
    }
    10% {
        width: 64px;
        height: 4px
    }
    20% {
        width: 48px;
        height: 4px
    }
    30% {
        width: 64px;
        height: 4px
    }
    40% {
        width: 40px;
        height: 4px
    }
    50% {
        width: 24px;
        height: 4px
    }
    60% {
        width: 8px;
        height: 4px
    }
    70% {
        width: 56px;
        height: 4px
    }
    80% {
        width: 16px;
        height: 4px
    }
    90% {
        width: 48px;
        height: 4px
    }
    100% {
        width: 40px;
        height: 4px
    }
}