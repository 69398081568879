#quoteloader-ship_to {
    animation: quoteloader-ship_to__to 3000ms linear infinite normal forwards
}

@keyframes quoteloader-ship_to__to {
    0% {
        transform: translate(388.125062px, 195.313848px)
    }
    100% {
        transform: translate(-83.472558px, 195.313848px)
    }
}

#quoteloader-truck_to {
    animation: quoteloader-truck_to__to 3000ms linear infinite normal forwards
}

@keyframes quoteloader-truck_to__to {
    0% {
        transform: translate(-52.313259px, 255.715110px)
    }
    83.333333% {
        transform: translate(352.298289px, 255.715110px)
    }
    100% {
        transform: translate(352.298289px, 255.715110px)
    }
}

#quoteloader-plane_to {
    animation: quoteloader-plane_to__to 3000ms linear infinite normal forwards
}

@keyframes quoteloader-plane_to__to {
    0% {
        transform: translate(-90.184844px, 129.803725px)
    }
    16.666667% {
        transform: translate(-90.184844px, 129.803725px)
    }
    66.666667% {
        transform: translate(341.798880px, 30.463490px)
    }
    100% {
        transform: translate(341.798880px, 30.463490px)
    }
}

#quoteloader-plane_ts {
    animation: quoteloader-plane_ts__ts 3000ms linear infinite normal forwards
}

@keyframes quoteloader-plane_ts__ts {
    0% {
        transform: rotate(-19.314938deg) scale(0.603868, 0.600000)
    }
    16.666667% {
        transform: rotate(-19.314938deg) scale(0.603868, 0.600000)
    }
    66.666667% {
        transform: rotate(-19.314938deg) scale(0.200000, 0.200000)
    }
    100% {
        transform: rotate(-19.314938deg) scale(0.200000, 0.200000)
    }
}
