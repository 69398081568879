.cc-window .cc-compliance.cc-highlight .cc-deny.deny-cookies {
  background-color: #333333;
  color: #fff;
  font-size: 0.9em;
  line-height: 22px;
  padding: 0.4em 0.8em;
}

.cc-window .cc-message .cc-link {
  color: #93bf46;
  text-decoration: underline;
}

.react-datepicker .react-datepicker__header {
  background-color: #ffffff;
}

.react-datepicker .react-datepicker__day-name {
  color: #A0AEC0;
}

.react-datepicker-popper.calendar-popper {
  z-index: 3;
}
